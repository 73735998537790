module.exports = {
  siteTitle:
    'Ceon - Kwalitatief grafisch design (huisstijl, grafisch drukwerk, website,...) voor een betaalbare prijs.', // <title>
  manifestName: 'Ceon',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  // pathPrefix: `/gatsby-starter-eventually/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'CEON',
  subHeading: 'The importance of doing the simple things to perfection.',
  socialLinks: [
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:info@ceon.be',
      aria: 'mail',
    },
  ],
};
