import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
// import SlideShow from '../components/SlideShow';

// var settings = {
//   images: [
//     { url: require('../assets/images/bg03.webp'), position: 'center' },
//   ],
//   // Delay.
//   delay: 1000,
// };

const IndexPage = () => (
  <Layout>
    <Header />
    <Footer />
    {/* <SlideShow settings={settings} /> */}
  </Layout>
);

export default IndexPage;
