import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <footer id="footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url, aria } = social;
          return (
            <li>
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                class={`icon ${icon}`}
                aria-label={aria}
              >
                <span class="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </footer>
  );
}
